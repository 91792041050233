/* purgecss start ignore */
@tailwind base;
@tailwind components;

@font-face {
  font-family: "Lato";
  src: url("/font/lato/Lato-Thin");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("/font/lato/Lato-ThinItalic.ttf");
  font-style: italic;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/font/lato/Lato-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("/font/lato/Lato-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("/font/lato/Lato-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("/font/lato/Lato-RegularItalic.ttf");
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/font/lato/Lato-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/font/lato/Lato-BoldItalic.ttf");
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/font/lato/Lato-Black.ttf");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: url("/font/lato/Lato-BlackItalic.ttf");
  font-style: italic;
  font-weight: 900;
  font-display: swap;
}

/* purgecss end ignore */
@tailwind utilities;
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@font-face {
  font-family: "EASTMAN";
  src: url("../public/font/eastman-alt/EastmanAlternate-Regular.otf");
}

.grecaptcha-badge {
  visibility: hidden;
}
